import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import Helper from '../utils/helper';
import { DOMAIN_NAME, API_VERSION } from '../constants/api';
import { useFetchData } from '../context/FetchDataContext'; // Add this import

const { Dragger } = Upload;

const BulkUploadModal = ({ isVisible, onClose }) => {
  const [fileList, setFileList] = useState([]);
  const { setTriggerRefetch } = useFetchData(); // Add this line

  const props = {
    accept: '.csv, .xlsx',
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', fileList[0]);

    try {
      const authToken = Helper.getTokenFromLocalStorage();
      const response = await axios.post(`${DOMAIN_NAME}${API_VERSION}/url/bulkupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken,
        },
      });

      const { new_urls, old_urls } = response.data;
      message.success(`Upload successful! New URLs: ${new_urls}, Old URLs: ${old_urls}`);
      setFileList([]);
      setTriggerRefetch(true); // Add this line to trigger a refetch
      onClose();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access attempt detected.');
        message.error('Session expired, redirecting to login page.');
        setTimeout(() => {
          Helper.handleLogout();
        }, 3000);
      } else {
        console.error('An unexpected error occurred:', error);
        message.error('Upload failed. Please try again.');
      }
    }
  };

  return (
    <Modal
      title="Bulk Upload URLs"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="upload" type="primary" onClick={handleUpload} disabled={fileList.length === 0}>
          Upload
        </Button>,
      ]}
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single CSV or XLSX file upload.</p>
      </Dragger>
    </Modal>
  );
};

export default BulkUploadModal;