// src/components/Navbar.js
import React, { useState } from 'react';
import { Menu, Button } from 'antd'; // Import Button from Ant Design
import { Link } from 'react-router-dom';
import ModalComponent from './CreateForm'; // Import the ModalComponent
import Helper from '../utils/helper';
import BulkUploadModal from './BulkUploadForm'; // Import the new component

const Navbar = () => {
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const [isBulkUploadVisible, setIsBulkUploadVisible] = useState(false);

  const handleCreateClick = () => {
    setIsCreateFormVisible(true); // Show the modal
  };

  const handleBulkUploadClick = () => {
    setIsBulkUploadVisible(true);
  };

  return (
    <>
      <Menu mode="horizontal" style={{ justifyContent: "flex-end" }}>
        {/* Left-aligned items */}
        <Menu.Item key="home">Home</Menu.Item>
        <Menu.Item style={{ padding: '0 10px' }}>
          <Button onClick={handleCreateClick}>Create</Button>
        </Menu.Item>
        <Menu.Item style={{ padding: '0 10px' }}>
          <Button onClick={handleBulkUploadClick}>Bulk Upload</Button>
        </Menu.Item>

        {/* Spacer to push Logout to the end */}
        <div style={{ flex: 'auto' }} />

        {/* Right-aligned item */}
        <Menu.Item onClick={Helper.handleLogout}><span>Logout</span></Menu.Item>
        {/* <Menu.Item key="logout"><Link to="/logout">Logout</Link></Menu.Item> */}
      </Menu>
      {/* Render the ModalComponent and pass props */}
      <ModalComponent isVisible={isCreateFormVisible} onClose={() => setIsCreateFormVisible(false)} />
      <BulkUploadModal isVisible={isBulkUploadVisible} onClose={() => setIsBulkUploadVisible(false)} />
    </>
  );
};

export default Navbar;
