// FetchDataContext.js
import React, { createContext, useState, useContext } from 'react';

const FetchDataContext = createContext();

export const FetchDataProvider = ({ children }) => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  return (
    <FetchDataContext.Provider value={{ triggerRefetch, setTriggerRefetch }}>
      {children}
    </FetchDataContext.Provider>
  );
};

export const useFetchData = () => useContext(FetchDataContext);
