import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, DatePicker, Button, Tooltip, Popconfirm, message} from 'antd';
import axios from 'axios';
import { CopyOutlined } from '@ant-design/icons';
import { useFetchData } from '../context/FetchDataContext'; // Ensure this path matches your project structure
import Helper from '../utils/helper';
import { DOMAIN_NAME, API_VERSION } from '../constants/api';

const ModalComponent = ({ isVisible, onClose }) => {
  const [response, setResponse] = useState(null);
  const [form] = Form.useForm();
  const { setTriggerRefetch } = useFetchData(); // Destructure setFetchData from context

  const handleOk = async (values) => {
    try {
      const authToken = Helper.getTokenFromLocalStorage();
      const authorizationHeader = authToken ? `${authToken}` : '';
      const response = await axios.post(`${DOMAIN_NAME}${API_VERSION}/url/create`, {
        URL: values.url,
        addNew: 0,
        expiryDate: values.expiry.format('YYYY-MM-DD'),
      }, {
        headers : {
          Authorization: authorizationHeader
        }
      }
    );
      console.log(response.data);
      setResponse({ shortenedUrl: response.data.shortURL });
      message.success("Shortened URL generated successfully!")
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        console.error('Unauthorized access attempt detected.');
        message.error('Session expired, redirecting to login page.');
        setTimeout(() => {
          Helper.handleLogout();
        }, 3000); 
      } else {
          console.error('An unexpected error occurred:', error);
      }
    }
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
    setResponse(null); // Clear the response state
    console.log("handling")
    setTriggerRefetch(true); // Trigger refetching of data in ListPage
  };

  useEffect(() => {
    // Reset the form when the modal becomes invisible
    if (!isVisible) {
      form.resetFields();
      setResponse(null); // Clear the response state
    }
  }, [isVisible, form]);

  return (
    <Modal title="Create URL" visible={isVisible} onCancel={handleCancel} footer={null}>
      <Form form={form} onFinish={handleOk}>
        <Form.Item name="url" label="URL" rules={[{ required: true, message: 'Please input the URL' }]}>
          <Input placeholder="Enter URL" />
        </Form.Item>
        <Form.Item name="expiry" label="Expiry Date" rules={[{ required: true, message: 'Please select an expiry date' }]}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        {response && (
          <div>
            <span>Shortened URL:</span>
            <span>{response.shortenedUrl}</span>
            <Tooltip title="Copy to clipboard">
              <Popconfirm icon={<CopyOutlined />} title="Copy?" onConfirm={() => navigator.clipboard.writeText(response.shortenedUrl)}>
                <Button type="link">Copy</Button>
              </Popconfirm>
            </Tooltip>
          </div>
        )}
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form>
    </Modal>
  );
};

export default ModalComponent;
