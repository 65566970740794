// LoginPage.js
import React, { useState } from 'react';
import { Button, Form, Input, Card, message } from 'antd';
import axios from 'axios';
import '../styles/LoginPage.css'; // Assuming you create a CSS file for custom styles
import { DOMAIN_NAME, API_VERSION } from '../constants/api';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(`${DOMAIN_NAME}${API_VERSION}/authenticate`, {}, {
        auth: {
          username,
          password,
        },
      });
      console.log("this is response", response)
      if (response.data.token) {
        console.log("logged in");
        console.log(response.data.token)
        // Calculate the date 5 hours from now
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 10);
        var token = response.data.token
        // Store the token in local storage along with its expiry date
        localStorage.setItem('authToken', JSON.stringify({
            token: token,
            expiryDate: expiryDate.toUTCString()
        }));
        
        window.location.reload()
    } else {
        message.error("Login Failed");
        // alert('Login failed');
    }
    
    } catch (error) {
      console.error(error);
      message.error("Login Failed")
    }
  };

  return (
    <div className="login-page-container">
      <Card title="Login: URL Shortening Service" className="login-card">
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Username" name="username">
            <Input value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="long-button">Log In</Button>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;
