// App.js
import React from 'react';
import { BrowserRouter as Router, HashRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import ListPage from './components/ListPage';
import LoginPage from './components/LoginPage'; // Import LoginPage
import { FetchDataProvider } from './context/FetchDataContext';

function App() {
  const isAuthenticated = localStorage.getItem('authToken') !== null;
  // Check if authToken exists in cookies

  const handleLogout = () => {
    document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // Clear authToken cookie
    window.location.href = '/login'; // Redirect to login after logging out
  };

  return (
    <FetchDataProvider>
      
        {!isAuthenticated && <LoginPage />}
        {isAuthenticated && (
          <>
            <Navbar />
            
             <ListPage />
              {/* <Route path="/logout" element={<button onClick={handleLogout}>Logout</button>} /> Use button for logout action */}
            
          </>
        )}
    </FetchDataProvider>
  );
}

export default App;
