import React, { useEffect, useState } from 'react';
import { Table, Input, Typography, Tooltip, message, Button, DatePicker } from 'antd'; // Add DatePicker import
import axios from 'axios';
import moment from 'moment'; // Import moment
import { useFetchData } from '../context/FetchDataContext'; // Import useFetchData hook
import EditModal from './EditForm';
import { CopyOutlined } from '@ant-design/icons';
import Helper from '../utils/helper';
import { DOMAIN_NAME, API_VERSION } from '../constants/api';

const ListPage = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { triggerRefetch, setTriggerRefetch } = useFetchData(); // Destructure fetchData from context
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [copiedUrlIndex, setCopiedUrlIndex] = useState(null);
  const [createdAtFilter, setCreatedAtFilter] = useState(null);
  const [updatedAtFilter, setUpdatedAtFilter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = Helper.getTokenFromLocalStorage();
        const authorizationHeader = authToken ? `${authToken}` : '';

        const result = await axios.get(`${DOMAIN_NAME}${API_VERSION}/url/list`, {
          headers: {
            Authorization: authorizationHeader
          }
        });
        setData(result.data);
        setTriggerRefetch(false)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.error('Unauthorized access attempt detected.');
          message.error('Session expired, redirecting to login page.');
          setTimeout(() => {
            Helper.handleLogout();
          }, 3000); 
        } else {
            console.error('An unexpected error occurred:', error);
        }
        
      }
    };
    fetchData();
  }, [triggerRefetch]); // Add fetchData as dependency to trigger refetching

  const downloadCSV = () => {
    const csvContent = [
      // CSV header
      ['Serial No.', 'URL', 'Short URL', 'Expiry Date', 'Created At', 'Updated At'],
      // CSV data
      ...data.map((item, index) => [
        index + 1,
        item.URL,
        item.shortURL,
        formatDate(item.expiryDate),
        formatDate(item.created_at),
        formatDate(item.updated_at)
      ])
    ]
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'url_list.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const showEditModal = (record) => {
      setSelectedUrl(record);
      setIsEditModalVisible(true);
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(function() {
      console.log('Copying to clipboard was successful!');
      setCopiedUrlIndex(index); // Update state to indicate which icon was clicked
      setTimeout(() => setCopiedUrlIndex(null), 200); // Reset state after 1 second
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return moment(date).format('Do MMMM YYYY'); // Format date as "23rd August 2024"
  };

  function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

  const columns = [
    { 
      title: 'Serial No.', 
      key: 'serialNo', 
      render: (_, __, index) => index + 1, 
    },
    { 
      title: 'URL', 
      dataIndex: 'URL', 
      key: 'url',
      sorter: (a, b) => a.URL.localeCompare(b.URL),
      render: text => (
        <Tooltip title={text}>
          <Typography.Text ellipsis={true} style={{ maxWidth: 230 }}>{text.substring(0, 50)}...</Typography.Text>
        </Tooltip>
      ), 
    },
    { 
      title: 'Short URL', 
      dataIndex: 'shortURL', 
      key: 'shortUrl',
      render: (text, record, index) => (
        <div>
          <CopyOutlined 
            onClick={() => copyToClipboard(text, index)} 
            style={{ 
              marginLeft: '10px', 
              cursor: 'pointer',
              color: copiedUrlIndex === index ? '#52c41a' : '' // Change color if this is the copied URL
            }} 
          /> {/* Copy icon */}
          {text} {/* Display the short URL */}
        </div>
      ),
    },
    { 
      title: 'Expiry Date', 
      dataIndex: 'expiryDate', 
      key: 'expiryDate',
      sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
      render: formatDate, 
    },
    { 
      title: 'Created At', 
      dataIndex: 'created_at', 
      key: 'createdAt',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: formatDate,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            onChange={(date) => {
              setSelectedKeys(date ? [date.format('YYYY-MM-DD')] : []);
              setCreatedAtFilter(date);
            }}
            onOk={() => confirm()}
            value={createdAtFilter}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button onClick={() => { clearFilters(); setCreatedAtFilter(null); }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const createdAt = moment(record.created_at);
        return createdAt.format('YYYY-MM-DD') === value;
      },
    },
    { 
      title: 'Updated At', 
      dataIndex: 'updated_at', 
      key: 'updatedAt',
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      render: formatDate,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            onChange={(date) => {
              setSelectedKeys(date ? [date.format('YYYY-MM-DD')] : []);
              setUpdatedAtFilter(date);
            }}
            onOk={() => confirm()}
            value={updatedAtFilter}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button onClick={() => { clearFilters(); setUpdatedAtFilter(null); }} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const updatedAt = moment(record.updated_at);
        return updatedAt.format('YYYY-MM-DD') === value;
      },
    },
    { 
      title: 'Action', 
      key: 'action', 
      render: (_, record) => (
        <a onClick={() => showEditModal(record)}>Edit</a>// Placeholder for the Edit button
      ),
    }
  ];

  return (
    <div style={{ width: '85%', margin: 'auto', height: '700px', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
        <Input.Search
          placeholder="Search"
          onSearch={(value) => setSearchText(value)}
          style={{ width: '70%', marginRight: '10px' }}
        />
        <Button onClick={downloadCSV} type="default">
          Export
        </Button>
      </div>
      <Table
        dataSource={data.filter(item =>
          item.URL.includes(searchText) || item.shortURL.includes(searchText)
        )}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSize: 8,
          position: ['topRight'],
        }}
        defaultSortOrder={['desc']} // Specify default sort order for the "Updated At" column
        onChange={() => {}}
      />
      {isEditModalVisible && <EditModal isVisible={isEditModalVisible} onClose={() => setIsEditModalVisible(false)} selectedUrl={selectedUrl} />}
    </div>
  );
};

export default ListPage;
