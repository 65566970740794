// src/utils/Helper.js

class Helper {
    /**
     * Retrieves the value of a cookie by its name.
     * @param {string} name The name of the cookie.
     * @returns {string|null} The value of the cookie if found, otherwise null.
     */
    static getTokenFromLocalStorage() {
        const storedToken = localStorage.getItem('authToken');
        
        if (!storedToken) {
            return null; // No token found
        }
        
        const { token, expiryDate } = JSON.parse(storedToken);
        
        const currentDate = new Date();
        const storedExpiryDate = new Date(expiryDate);
        
        if (currentDate > storedExpiryDate) {
            // Token is expired, remove it from local storage
            localStorage.removeItem('authToken');
            return null;
        }
        
        return token;
    }
    

    static handleLogout = () => {
        console.log("removing item")
        localStorage.removeItem('authToken'); // Remove authToken from local storage
        window.location.reload() // Redirect to login after logging out
    };
    
}

export default Helper;
