import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Button, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useFetchData } from '../context/FetchDataContext';
import Helper from '../utils/helper';
import { DOMAIN_NAME, API_VERSION } from '../constants/api';

const EditModal = ({ isVisible, onClose, selectedUrl }) => {
  const [form] = Form.useForm();
  const { setTriggerRefetch } = useFetchData();

  useEffect(() => {
    if (selectedUrl) {
      console.log(selectedUrl)
      form.setFieldsValue({
        url: selectedUrl.URL,
        shorturl: selectedUrl.shortURL,
        expiryDate: moment(selectedUrl.expiryDate),
      });
    }
  }, [selectedUrl, form]);

  const handleSubmit = async (values) => {
    try {
      const authToken = Helper.getTokenFromLocalStorage();
      const authorizationHeader = authToken ? `${authToken}` : '';
      await axios.post(`${DOMAIN_NAME}${API_VERSION}/url/update`, {
        url: values.url,
        shorturl: values.shorturl,
        expiryDate: values.expiryDate.format('YYYY-MM-DD'),
      },{
        headers : {
          Authorization: authorizationHeader
        },
      });
      message.success("Shortened URL updated successfully!")
      
      onClose();
      setTriggerRefetch(true); 
      
      // Close the modal after successful update
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        console.error('Unauthorized access attempt detected.');
        message.error('Session expired, redirecting to login page.');
        setTimeout(() => {
          Helper.handleLogout();
        }, 3000); 
      } else {
          console.error('An unexpected error occurred:', error);
      }
      console.error('Error updating URL:', error);
    }
  };

  return (
    <Modal title="Edit URL" visible={isVisible} onCancel={onClose} footer={null}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="url" label="URL">
          <Input />
        </Form.Item>
        <Form.Item name="shorturl" label="Short URL">
          <Input readOnly  /> 
        </Form.Item>
        <Form.Item name="expiryDate" label="Expiry Date">
          <DatePicker />
        </Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form>
    </Modal>
  );
};

export default EditModal;
